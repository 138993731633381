'use client'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { JetBrains_Mono as JetBrainsMono } from 'next/font/google'
import { usePathname } from 'next/navigation'
import { ReactNode } from 'react'
import ReactGA from 'react-ga4'

import StyledComponentsRegistry from '@/config/StyleSheetManager'

import useMessageOnPathname from '../hooks/useMessageOnPathname'

const TRACKING_ID = process.env.NEXT_PUBLIC_TRACKING_ID || ''
ReactGA.initialize(TRACKING_ID)

export const jetbrainsMono = JetBrainsMono({
  display: 'swap',
  subsets: ['latin'],
})

interface ILayout {
  children: ReactNode
}

const queryClient = new QueryClient()

const Layout = ({ children }: ILayout) => {
  const pathname = usePathname()
  useMessageOnPathname({ type: 'pathChange', content: { pathname: pathname } })
  return (
    <html
      className={jetbrainsMono.className}
      lang="es"
    >
      <body>
        <StyledComponentsRegistry>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </StyledComponentsRegistry>
      </body>
    </html>
  )
}

export default Layout
