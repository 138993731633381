import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

type UseMessageOnPathname = {
  type: string
  content: {
    [key: string]: unknown
  }
}

const useMessageOnPathname = (props: UseMessageOnPathname) => {
  const pathname = usePathname()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isIframe = window.self !== window.top
      if (isIframe) window.parent.postMessage(props, '*')
    }
  }, [pathname, props])
}

export default useMessageOnPathname
